<template>
  <div id='header'>
    <div @click="getIndex(1)" class="header_logo">
      <img src="@/assets/image/logo.png" alt="">
    </div>
    <div class="header_content">
      <template v-for="item in navData">
        <div @click="getIndex(item.id)" class="content_title"><span :class="currentIndex === item.id ? 'active' : ''">{{item.class_name}}</span></div>
      </template>
      <div @click="toContact" class="contact"><Icon type="ios-mail-outline" size="24" />{{contactUs.class_name}}<Icon type="md-arrow-forward" size="18" /></div>
    </div>
  </div>
</template>

<script>
import {getNav} from '@api';

export default {
  data () {
    return {
      navData: [],
      contactUs: {},
    }
  },
  props: {
    currentIndex: {
      type: Number,
      value: 1
    }
  },
  created() {
    const data = JSON.parse(localStorage.getItem('navData'))
    if(!data) {
      this.getNavData()
    }else {
      if(new Date().getTime() - data.time > 5 * 60 * 1000) {
        this.getNavData()
      }else {
        this.navData = data.menu
        this.contactUs = data.menu_contact_us[0]
      }
    }
  },
  methods: {
    getIndex(id) {
      const {currentIndex} = this
      if(currentIndex === id) return
      if(id === 1) this.$router.push({name: 'Home'})
      if(id === 2) this.$router.push({name: 'About'})
      if(id === 3) this.$router.push({name: 'Business'})
      if(id === 4) this.$router.push({name: 'News'})
    },
    toContact() {
      if(this.$route.name === 'Contact') return
      this.$router.push({name: 'Contact'})
    },
    getNavData() {
      getNav().then(res => {
        const {menu, menu_contact_us} = res.data
        const navData = res.data
        this.navData = menu
        this.contactUs = menu_contact_us[0]
        navData.time = new Date().getTime()
        localStorage.setItem('navData', JSON.stringify(navData))
      })
    }
  }
}
</script>

<style lang='less' scoped>
#header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  // max-width: 1648px;
  min-width: 1200px;
  height: 90px;
  padding: 0 136px;
  color: #000;
  .header_logo {
    animation-name: header_left_shift;
    animation-duration: 1s;
    cursor: pointer;
  }
  .header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 950px;
    font-size: 18px;
    cursor: pointer;
    animation-name: header_right_shift;
    animation-duration: 1s;
    .content_title {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 55px;
      text-align: center;
      line-height: 55px;
      &:hover {
        color: #00C0F8;
      }
      span {
        position: relative;
        font-weight: bold;
      }
      .active {
        border-bottom: 6px solid #00C0F8;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 8px;
          background: #43A3E0;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 16px;
          width: 8px;
          height: 8px;
          background: #D90D0D;
        }
      }
    }
    .contact {
      width: 210px;
      height: 55px;
      text-align: center;
      line-height: 55px;
      font-size: 14px;
      color: #fff;
      background: #000;
      transition: 1s;
      .ivu-icon-ios-mail-outline {
        padding-right: 14px;
      }
      .ivu-icon-md-arrow-forward {
        padding-left: 25px;
      }
    }
    .contact:hover {
      color: #000;
      background: #fff;
      border: 1px solid #000;
    }
  }
  @keyframes header_left_shift {
    0% {
      transform: translateX(-136px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes header_right_shift {
    0% {
      transform: translateY(-90px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
</style>