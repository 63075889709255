<template>
  <div id='footer'>
    <div class="footer_nav">
      <div class="nav_left">
        <img src="@/assets/image/logo_f.png" alt="">
        <p class="mob">Tel：{{footer.phone}}</p>
        <p class="info">{{footer.email}}</p>
        <p class="address">{{footer.address}}</p>
      </div>
      <div class="nav_right">
        <div class="header_item">
          <div v-for="item in navData" :key="item.id" @click="getIndex(item.id)" class="header_item_content"><span>{{item.class_name}}</span></div>
        </div>
        <div @click="toContact" class="contact"><Icon type="ios-mail-outline" size="24" />{{contactUs.class_name}}<Icon type="md-arrow-forward" size="18" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNav} from '@api'

export default {
  data() {
    return {
      navData: [],
      contactUs: {},
      footer: {}
    }
  },
  props: {
    currentIndex: {
      type: Number,
      value: 1
    }
  },
  created() {
    const data = JSON.parse(localStorage.getItem('navData'))
    if(!data) {
      this.getNavData()
    }else {
      if(new Date().getTime() - data.time >  5 * 60 * 1000) {
        this.getNavData()
      }else {
        this.navData = data.menu
        this.contactUs = data.menu_contact_us[0]
        this.footer = data.contact_us
      }
    }
  },
  methods: {
    getIndex(id) {
      const {currentIndex} = this
      if(currentIndex === id) return
      if(id === 1) this.$router.push({name: 'Home'})
      if(id === 2) this.$router.push({name: 'About'})
      if(id === 3) this.$router.push({name: 'Business'})
      if(id === 4) this.$router.push({name: 'News'})
    },
    toContact() {
      if(this.$route.name === 'Contact') return
      this.$router.push({name: 'Contact'})
    },
    getNavData() {
      getNav().then(res => {
        const {menu, menu_contact_us, contact_us} = res.data
        const navData = res.data
        this.navData = menu
        this.contactUs = menu_contact_us[0]
        this.footer = contact_us
        navData.time = new Date().getTime()
        localStorage.setItem('navData', JSON.stringify(navData))
      })
    }
  }
}
</script>

<style lang='less' scoped>
#footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 463px;
  color: #000;
  .footer_nav {
    display: flex;
    justify-content: space-between;
    max-width: 1696px;
    min-width: 1200px;
    height: 463px;
    background: #F7F7F8;
    border-top-right-radius: 100px;
    padding: 100px 136px;
    .nav_left {
      color: #333;
      font-size: 18px;
      .mob {
        margin-top: 16px;
      }
    }
    .nav_right {
      overflow: hidden;
      .header_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        .header_item_content {
          width: 185px;
          height: 55px;
          text-align: center;
          line-height: 55px;
          span {
            position: relative;
            padding-bottom: 8px;
            font-weight: bold;
          }
        }
        .header_item_content:hover {
          color: #2299E1;
        }
      }
      .contact {
        float: right;
        width: 210px;
        height: 55px;
        margin-top: 60px;
        color: #fff;
        background: #000;
        font-size: 14px;
        text-align: center;
        line-height: 55px;
        cursor: pointer;
        animation-name: header_left_shift;
        animation-duration: 1s;
        animation-delay: 1.5s;
        animation-fill-mode: backwards;
        transition: 1s;
        .ivu-icon-ios-mail-outline {
          padding-right: 14px;
        }
        .ivu-icon-md-arrow-forward {
          padding-left: 25px;
        }
      }
      .contact:hover {
        color: #000;
        background: #fff;
        border: 1px solid #000;
      }
    }
  }
}
</style>