<template>
  <div id='business'>
    <Header :currentIndex="id"/>
    <h1 class="business_title">BUSINESS</h1>
    <div class="line"></div>
    <div v-if="dataInfo.length" class="services">
      <div class="services_title">
        <p>{{dataInfo[0].class_name}}</p>
        <p>{{dataInfo[0].class_subhead_name}}</p>
      </div>
      <div class="interval"></div>
      <div class="services_contnet">
        <div v-for="(item, index) in dataInfo[0].child" :key="index" class="services_contnet_item">
          <p class="services_contnet_index">{{`0${index + 1}`}}</p>
          <img :src="item.icon" alt="" class="services_contnet_img">
          <p @click="toOtherPages(item.title_url)" class="services_contnet_title">{{item.title}}</p>
          <div v-html="item.content" class="services_contnet_text"></div>
        </div>
      </div>
      <p class="partner_title">{{dataInfo[1].class_name}}</p>
      <div class="interval"></div>
      <div v-html="dataInfo[1].class_intro" class="partner_info"></div>
      <div class="partner_name">
        <div class="partner_name_item">
          <img src="@/assets/image/business/baby.png" alt="">
          <p>Baby and maternity</p>
        </div>
        <div class="partner_name_item">
          <img src="@/assets/image/business/health.png" alt="">
          <p>Health Care</p>
        </div>
        <div class="partner_name_item">
          <img src="@/assets/image/business/skincare.png" alt="">
          <p>Beauty-skincare</p>
        </div>
        <div class="partner_name_item">
          <img src="@/assets/image/business/beauty.png" alt="">
          <p>Beauty-bath, body & hair</p>
        </div>
        <div class="partner_name_item">
          <img src="@/assets/image/business/pet.png" alt="">
          <p>Pet Supplies, food & accessories</p>
        </div>
      </div>
      <div class="partner_brand">
        <div v-for="item in dataInfo[1].child" :key="item.id" class="partner_brand_item">
          <img :src="item.icon" alt="">
        </div>
      </div>
      <p class="partner_title">{{dataInfo[2].class_name}}</p>
      <div class="interval"></div>
      <div v-html="dataInfo[2].class_intro" class="partner_info"></div>
      <div class="partner_brand">
        <div v-for="item in dataInfo[2].child" :key="item.id" class="partner_brand_item">
          <img :src="item.icon" alt="">
        </div>
      </div>
    </div>
    <div v-else class="no_data"></div>
    <div class="bg_left"><img src="@assets/image/bg_left.png" alt=""></div>
    <div class="bg_right"><img src="@assets/image/bg_right.png" alt=""></div>
    <Footer :currentIndex="id"/>
  </div>
</template>

<script>
import Header from '@cpt/Header'
import Footer from '@cpt/Footer'
import {getContent} from '@api'

export default {
  data () {
    return {
      id: 3,
      dataInfo: []
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.getData(this.id)
  },
  methods: {
    getData(id) {
      getContent({id}).then(res => this.dataInfo = res.data)
    },
    toOtherPages(url) {
      if(url) window.open(url)
    }
  }
}
</script>

<style lang='less' scoped>
#business {
  position: relative;
  overflow: hidden;
  .business_title {
    width: 1200px;
    margin: 178px auto 0;
    font-size: 49px;
    font-weight: bold;
    animation: move 1s;
  }
  @keyframes move {
    0% {
      transform: translateX(-500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .line {
    position: absolute;
    top: 305px;
    right: 0;
    width: 60%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
  }
  .services {
    width: 1200px;
    margin: 133px auto 105px;
    .services_title {
      font-size: 28px;
      text-align: center;
      p {
        font-weight: bold;
      }
    }
    .interval {
      width: 1px;
      height: 62px;
      background: #e5e5e5;
      margin: 32px auto;
    }
    .services_contnet {
      display: flex;
      justify-content: space-evenly;
      .services_contnet_item {
        width: 280px;
        text-align: center;
        .services_contnet_index {
          margin-top: 28px;
          font-size: 16px;
          font-weight: bold;
        }
        .services_contnet_img {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-top: 32px;
        }
        .services_contnet_title {
          margin-top: 32px;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
        .services_contnet_text {
          margin-top: 32px;
          font-size: 14px;
          color: #666;
          text-align: left;
          // word-break:break-all;
        }
      }
    }
    .partner_title {
      margin-top: 72px;
      font-size: 28px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
    .partner_info {
      padding: 0 100px;
      color: #666;
      font-size: 14px;
      text-align: center;
    }
    .partner_name {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
      .partner_name_item {
        width: 240px;
        height: 190px;
        margin-left: -1px;
        border: 1px solid #707070;
        text-align: center;
        img {
          width: 72px;
          height: 72px;
          margin-top: 38px;
        }
        p {
          margin-top: 25px;
          color: #666;
          font-size: 14px;
        }
      }
    }
    .partner_brand {
      display: flex;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 37px;
      .partner_brand_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 132px;
        margin-left: -1px;
        border: 1px solid #E5E5E5;
        img {
          width: 150px;
          height: 80px;
        }
      }
    }
  }
  .bg_left {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }
  .bg_right {
    position: absolute;
    top: 875px;
    right: 0;
    z-index: -1;
  }
  .no_data {
    width: 100%;
    height: 143px;
  }
}
</style>1